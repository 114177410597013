/* eslint-disable */
export const breakpoints = {
  methods: {
    isMobile() {
      return window.innerWidth <= 960
    },
    isTablet() {
      return window.innerWidth > 960 && window.innerWidth < 1200
    },
    isDesktop() {
      return window.innerWidth > 960 // this used to be 1200, changed to make menu work on smaller screen
    }
  }
}
